<template>
	<b-modal ref="modalLocalisation" @hidden="resetModal" @shown="modalShown">
		<template slot="modal-header" slot-scope="{ close }">
			<h2 class="mx-auto my-auto">{{ $t('monte.stock_semence.ajouter') }}</h2>
			<i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'times-circle']" /></i>
		</template>
		<template slot="default">
			<div class="row">
				<div class="col-12" v-if="form_message !== ''">
				    <ErrorAlert :messageI18n="form_message" />
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<label for="localisation">{{ $t('monte.stock_semence.categorie_parent') }}</label>
					<e-select
						v-model="parent"
						id="localisation"
						track-by="semencelocalisation_libelle"
						label="semencelocalisation_libelle"
						:placeholder="$t('monte.stock_semence.rechercher_categorie')"
						:selectedLabel="$t('global.selected_label')"
						:options="localisation_formatted"
						:searchable="true"
						:show-labels="false"
						:allow-empty="true"
						:sortable="false"
						class="form-group"
					>
						<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.semencelocalisation_libelle }}</span></template>
					</e-select>
				</div>

				<div class="col-12">
					<div class="form-group">
						<label for="libelle">{{ $t('monte.stock_semence.localisation_nom') }}</label>
						<input
							id="libelle"
							type="text"
							class="form-control"
							:placeholder="$t('monte.stock_semence.localisation_nom')"
							v-model="libelle"
						>
					</div>
				</div>

				<template v-if="hasCongelAccess && !parent">
					<div class="col-12">
						<div class="form-group">
	                        <b-form-checkbox
	                            id="prestockage"
	                            v-model="prestockage"
	                        >
	                        	<label for="prestockage">{{ $t('monte.stock_semence.cuve_prestockage') }}</label>
	                        </b-form-checkbox>
						</div>
					</div>
					<div class="col-12">
						<div class="form-group">
	                        <b-form-checkbox
	                            id="transport"
	                            v-model="transport"
	                        >
	                        	<label for="transport">{{ $t('monte.stock_semence.cuve_transport') }}</label>
	                        </b-form-checkbox>
						</div>
					</div>
				</template>
				<div v-if="hasCongelAccess" class="col-12">
					<div class="form-group">
                        <b-form-checkbox
                            id="has_floor"
                            v-model="has_floor"
                        >
                        	<label for="has_floor">{{ $t('monte.stock_semence.has_floor') }}</label>
                        </b-form-checkbox>
					</div>
					<div v-if="has_floor" class="form-group">
                       <label for="nb_floors">{{ $t('monte.stock_semence.nb_floors') }}</label>
                       <b-input
							id="nb_floors"
							type="number"
							class="form-control"
							:placeholder="$t('monte.stock_semence.nb_floors')"
							v-model="nb_floors"
						/>
					</div>
				</div>
			</div>

		</template>

		<template slot="modal-footer" class="justify-content-center">
			<b-button variant="primary" @click="saveLocalisationForm()" :disabled="processing">
				<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
				{{ $t('global.enregistrer') }}
			</b-button>
		</template>
	</b-modal>
</template>

<script type="text/javascript">
    import Common from '@/assets/js/common.js'
    import StockSemence from '@/mixins/StockSemence.js'	
    import _isEmpty from 'lodash/isEmpty'

	export default {
		name: 'ModalLocSemence',
		props: ['localisation', 'refresh_table'],
		mixins: [StockSemence],
		data () {
			return {
				processing: false,
				form_message: '',
				parent: null,
				libelle: '',
				localisations: [],
				prestockage: false,
				has_floor: false,
				nb_floors: 0,
				transport: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				if(!_isEmpty(this.localisation)) {
					this.libelle = this.localisation.semencelocalisation_libelle
					this.prestockage = this.localisation.semencelocalisation_prestockage
					this.transport = this.localisation.semencelocalisation_transport
					this.has_floor = this.localisation.semencelocalisation_nb_floors > 0
					this.nb_floors = this.localisation.semencelocalisation_nb_floors
					let parent = await this.getLocalisationSemenceById(this.localisation.semencelocalisation_parent)
					this.parent = parent[0]
				}

				const localisations = await this.getLocalisationSemence()
				this.localisations = localisations.filter(loc => !loc.semencelocalisation_is_floor && !loc.semencelocalisation_transport)

				if(!_isEmpty(this.localisation)) {
					this.localisations = this.localisations.filter(loc => loc.semencelocalisation_id !== this.localisation.semencelocalisation_id )
				}
			},

			openModal() {
				this.$refs.modalLocalisation.show()
			},

			async saveLocalisationForm() {
				if(!this.libelle) {
					this.form_message = "formulaire.erreur_champs_non_remplis"
					return
				}

				try {
					this.processing = true

					this.parent = _isEmpty(this.parent) ? {semencelocalisation_id: 0} : this.parent

					let id = null
					if(!_isEmpty(this.localisation)) {
						id = this.localisation.semencelocalisation_id
						await this.updateLocSemence(this.localisation.semencelocalisation_id, this.parent.semencelocalisation_id, this.libelle, this.prestockage, this.transport, this.nb_floors)
					} else {
						id = await this.createLocSemence(this.parent.semencelocalisation_id, this.libelle, this.prestockage, this.transport, this.nb_floors)
					}

					if(id < 0) {
						await this.$sync.force(true, true)
						id = this.$sync.replaceWithReplicated('semence_localisation', id)
					}

					if(this.has_floor && this.nb_floors && this.nb_floors > 0) {
						await this.createOrUpdateLocSemenceFloors(id, this.nb_floors, this.prestockage)
					}

					this.successToast('toast.info_save_succes')
					this.resetModal()

					this.$emit('update:refresh_table', !this.refresh_table)
					this.$refs.modalLocalisation.hide()
				}
				catch (e) {
					console.error(e)
					this.failureToast('toast.info_save_failed')
				}
			},

			resetModal() {
                this.processing = false
				this.form_message = ''
				this.parent = null
				this.libelle = ''
				this.localisations = []
				this.prestockage = false
				this.transport = false
				this.has_floor = false
				this.nb_floors = 0
            },

            modalShown() {
            	this.init_component()
            }
		},

		computed:{
			localisation_formatted: function() {
				return this.localisations
			},
            hasCongelAccess() {
                return this.$store.state.userAccess.hasCongelAccess
            },
		},

		watch:{
			localisation (val){
				this.init_component()
			},
			parent(val) {
				if(val) {
					this.prestockage = this.parent.semencelocalisation_prestockage
					this.transport = false
				}
			},
			has_floor(val) {
				if(!val) {
					this.nb_floors = 0
				}
			}
		},

		components: {
			draggable: () => import('vuedraggable'),
            ManageValues: () => import('@/components/Questions/ManageValues'),
            ViewValues: () => import('@/components/Questions/ViewValues'),
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
		}
	}

</script>